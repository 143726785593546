import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1aeb8118"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "autoList" }
const _hoisted_2 = { class: "autoList__content" }
const _hoisted_3 = { class: "fl-row" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_form = _resolveComponent("app-form")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_app_operates = _resolveComponent("app-operates")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_CreateGroup = _resolveComponent("CreateGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_form, {
        "search-list": _ctx.searchList,
        "search-data": _ctx.filterParams,
        onHandleSearch: _ctx.onRefreshTable
      }, null, 8, ["search-list", "search-data", "onHandleSearch"]),
      _createVNode(_component_app_operates, { mg: "0 0 16px 0" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: _ctx.onCreateGroup
          }, {
            default: _withCtx(() => [
              _createTextVNode("创建分组")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_app_table, {
        ref: "tableRef",
        size: "default",
        "row-key": "id",
        "show-pagination": true,
        columns: _ctx.tableColumns,
        data: _ctx.loadTableData
      }, {
        action: _withCtx(({ record }) => [
          _createElementVNode("span", _hoisted_3, [
            _createElementVNode("a", {
              href: "javascript:;",
              onClick: _withModifiers(($event: any) => (_ctx.handleRecords(record)), ["prevent"])
            }, "修改", 8, _hoisted_4),
            _createElementVNode("a", {
              href: "javascript:;",
              style: {"margin-left":"20px"},
              onClick: _withModifiers(($event: any) => (_ctx.deleteRecords(record)), ["prevent"])
            }, "删除", 8, _hoisted_5)
          ])
        ]),
        _: 1
      }, 8, ["columns", "data"])
    ]),
    _createVNode(_component_CreateGroup, {
      ref: "createGroupRef",
      onComplete: _ctx.createGroupComplete
    }, null, 8, ["onComplete"])
  ]))
}
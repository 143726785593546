
import { defineComponent, computed, reactive, ref, onMounted, nextTick, shallowRef } from 'vue';
import { message, Modal } from 'ant-design-vue';
import { noEmptyProp } from '@/utils/illuminate';
import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';
import { useStore } from 'vuex';
import moment, { Moment } from 'moment';
import '@amap/amap-jsapi-types';
import Detail from './trackDetail.vue';

type userInfoType = {
  userName: string
  userCode: string
  phone: string,
  id: number | string
}
type trackInfoType = {
  realDistance?: string | number,
  recommendDistance?: string | number,
  [propName:string]:any
}

export default defineComponent({
  name: 'TrackOBD',
  components: {
    Detail,
  },
  setup(props, ctx) {
    const store = useStore();
    // 详情
    const visible = ref<boolean>(false);

    // 地图
    let map: AMap.Map;
    let driving: any;
    let isInitMap = false;
    const initMap = (center:[number, number] = [116.397514, 39.907749]) => {
      map = new AMap.Map('mapContainer', {
        zoom: 11, // 级别
        center, // 中心点坐标
        viewMode: '3D', // 使用3D视图
      });
      isInitMap = true;
    };
    // 绘制地图
    const drawMap = (amapPoints:Record<string, any>[], obdPoints:Record<string, any>[]) => {
      if (!(amapPoints.length || obdPoints.length)) {
        message.warning('没有符合条件的轨迹信息');
        initMap();
        return;
      } 
      const { lng, lat } = obdPoints[0];
      const { lng: lngEnd, lat: latEnd } = obdPoints[obdPoints.length - 1];
      initMap([lng, lat]);
      // 添加起点和终点
      const markerStart = new AMap.Marker({
        position: [lng, lat],
        offset: new AMap.Pixel(-5, -5),
        icon: '//webapi.amap.com/theme/v1.3/markers/n/start.png', // 添加 Icon 图标 URL
        title: '北京',
      });
      const markerEnd = new AMap.Marker({
        position: [lngEnd, latEnd],
        offset: new AMap.Pixel(5, 5),
        icon: '//webapi.amap.com/theme/v1.3/markers/n/end.png', // 添加 Icon 图标 URL
        title: '北京',
      });

      map.add([markerStart, markerEnd]);
      
      // 高德API
      // AMap.plugin('AMap.Driving', () => {
      //   driving = new AMap.Driving({ map, policy: AMap.DrivingPolicy.LEAST_TIME });
      //   const passPoints = [
      //     { longitude: 116.430559, latitude: 39.883219 }, // 116.430559,39.883219
      //     { longitude: 117.21548, latitude: 39.066801 }, // 117.21548,39.066801
      //   ];
      //   driving.search(
      //     [passPoints[0].longitude, passPoints[0].latitude],
      //     [
      //       passPoints[passPoints.length - 1].longitude,
      //       passPoints[passPoints.length - 1].latitude,
      //     ],
      //     { waypoints: passPoints.slice(1, passPoints.length - 1).map((i: any) => ([i.longitude, i.latitude])) },
      //   );
      // });
      // 高德推荐轨迹
      const amapPath: AMap.LngLatLike[] = (amapPoints || []).map(({ lng, lat }) => [lng, lat]);
      if (amapPath.length) {
        const amapPolyLine = new AMap.Polyline({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
          map,
          path: amapPath,
          showDir: true,
          strokeColor: '#1BAC2E',
          strokeWeight: 6,
          lineJoin: 'round',
        }); 
      }
      // OBD真实轨迹
      const obdPath: AMap.LngLatLike[] = (obdPoints || []).map(({ lng, lat }) => [lng, lat]);
      if (obdPath.length) {
        const obdPolyLine = new AMap.Polyline({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
          map,
          path: obdPath,
          showDir: true,
          strokeColor: '#FF4D4F',
          strokeWeight: 6,
          lineJoin: 'round',
        });
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      map.setFitView();
    };
    const disabledDate = (current: Moment) => current && current >= moment().startOf('day');
    
    const filterParams = reactive({
      staffKeyword: undefined,
      vehicleKeyword: undefined,
      objDate: undefined,
    });

    let selectedUser = reactive<userInfoType>({
      id: '',
      userName: '',
      userCode: '',
      phone: '',
    });

    const getUserInfo = (val: userInfoType) => {
      if (val) {
        selectedUser = val;
      } else {
        selectedUser = {
          id: '',
          userName: '',
          userCode: '',
          phone: '',
        };
      }
    };

    const trackInfo = ref<trackInfoType>({});
    const onRefreshTrack = () => {
      const { staffKeyword, vehicleKeyword, objDate } = filterParams;
      if (!objDate) return message.warning('请选择日期');
      if (!(staffKeyword || vehicleKeyword)) return message.warning('请选择员工或车辆');
      request.post(
        createApiUrl('/newlinkSass/enterprise/get-refueling-vehicle-info'),
        { ...noEmptyProp({ ...filterParams, staffKeyword: selectedUser.id }), enterpriseId: store.state.User.enterprise?.enterpriseId }, { noEnterpriseId: true },
      ).then((res: Record<string, any>) => {
        const { code, data } = res;
        if (code !== 200) return;
        const { amapPoints, obdPoints, ...other } = data;
        trackInfo.value = other;
        nextTick(() => {
          drawMap(amapPoints || [], obdPoints || []);
        });
      }).catch(e => {
        message.error(e.message);
      });
    };

    onMounted(() => {
      nextTick(() => {
        initMap();
      });
    });

    return {
      disabledDate,
      filterParams,
      trackInfo,
      visible,
      onRefreshTrack,
      getUserInfo,
    };
  },
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2795e6fd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_2 = {
  key: 0,
  class: "buttom",
  style: {"margin-left":"20px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_PlusCircleOutlined = _resolveComponent("PlusCircleOutlined")!
  const _component_MinusCircleOutlined = _resolveComponent("MinusCircleOutlined")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    title: _ctx.title,
    width: "40%",
    "confirm-loading": _ctx.submiting,
    "destroy-on-close": true,
    "mask-closable": false,
    onOk: _ctx.onSubmit,
    onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close(false)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        ref: "formRef",
        model: _ctx.formData,
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.list, (item, index) => {
            return (_openBlock(), _createBlock(_component_a_form_item, {
              key: index,
              name: ['list', index, 'groupName'],
              label: "分组名称",
              rules: [{ required: true, message: '请输入分组名称', trigger: ['change', 'blur'] }]
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_a_input, {
                    value: item.groupName,
                    "onUpdate:value": ($event: any) => ((item.groupName) = $event),
                    valueModifiers: { trim: true },
                    style: {"width":"200px"},
                    placeholder: "请输入分组名称，限15个字",
                    maxlength: 15
                  }, null, 8, ["value", "onUpdate:value"]),
                  (_ctx.type === 'add')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        (index === _ctx.formData.list.length - 1)
                          ? (_openBlock(), _createBlock(_component_PlusCircleOutlined, {
                              key: 0,
                              onClick: ($event: any) => (_ctx.addRecord(item, index))
                            }, null, 8, ["onClick"]))
                          : _createCommentVNode("", true),
                        (index > 0)
                          ? (_openBlock(), _createBlock(_component_MinusCircleOutlined, {
                              key: 1,
                              style: {"margin-left":"20px"},
                              onClick: ($event: any) => (_ctx.deleteRecord(item, index))
                            }, null, 8, ["onClick"]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1032, ["name"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _: 1
  }, 8, ["visible", "title", "confirm-loading", "onOk"]))
}
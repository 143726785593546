
import { defineComponent, computed, reactive, ref, watch } from 'vue';
import { message, Modal } from 'ant-design-vue';

import { noEmptyProp } from '@/utils/illuminate';
import type { TableColumn } from '@/components/Table';
import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';
import CreateGroup from './parts/CreateGroup.vue';

export default defineComponent({
  components: {
    CreateGroup,
  },
  props: {
  
    energyType: {
      type: Number,
      default: 1,
    },
    activeKey: {
      type: Number,
      default: 1,
    },
  },
  setup(props, ctx) {
    const filterParams = reactive({ id: '', groupName: '' });
    const searchList = reactive([
      { 
        label: '序号',
        name: 'id',
        type: 'input',
        allowClear: true,
        placeholder: '序号',
      },
      { 
        label: '名称',
        name: 'groupName',
        type: 'input',
        allowClear: true,
        placeholder: '名称',
      },
    ]);
    const tableColumns = computed<TableColumn[]>(() => {
      const basic: TableColumn[] = [
        { title: '序号', dataIndex: 'id', align: 'center' },
        { title: '名称', dataIndex: 'groupName', align: 'center' },
        { title: '操作', dataIndex: 'action', width: '110px', align: 'center', slots: { customRender: 'action' } },
      ];
      return basic;
    });
    // 加载数据方法 必须为 Promise 对象
    const loadTableData = (parameter: any) => new Promise<void>((resolve, reject) => {
      request.post(createApiUrl('/newlinkSass/vehicleGroup/queryByPage'), { ...parameter, ...noEmptyProp(filterParams) })
        .then((res: any) => {
          const { data } = res;
          resolve(data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });
    const tableRef = ref<any>(null);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    // 删除
    const deleteRecords = (record: Record<string, string>) => {
      Modal.confirm({
        title: '删除分组',
        content: `确认删除${record.groupName}分组？删除成功后该分组下的车辆将转移至“未分组”中。`,
        okText: '确认删除',
        onOk: async () => {
          const res = await request.post(createApiUrl('/newlinkSass/vehicleGroup/delete'), { id: record.id });
          if (res && res.code === 200) { 
            message.success('分组删除成功。');
            onRefreshTable();
          }
        },
      });
    };

    const createGroupRef = ref();
    // 创建分组
    const onCreateGroup = () => {
      createGroupRef.value.handle('add');
    };
    // 修改
    const handleRecords = (record: Record<string, string>) => {
      createGroupRef.value.handle('edit', record);
    };
    const createGroupComplete = (flag: boolean) => {
      if (flag) { 
        onRefreshTable();
      }
    };

    watch(() => props.activeKey, newV => {
      if (newV === props.energyType) {
        filterParams.id = '';
        filterParams.groupName = '';
        onRefreshTable();
      }
    });

    return {
      filterParams,
      tableRef,
      tableColumns,
      loadTableData,
      onRefreshTable,
      searchList,
      createGroupRef,
      handleRecords,
      deleteRecords,
      onCreateGroup,
      createGroupComplete,
    };
  },
});

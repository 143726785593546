
import { defineComponent, onMounted, computed, reactive, ref, watch, watchEffect, PropType } from 'vue';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import { parseNumberOrZero } from '@/utils/illuminate';
import { QuestionCircleFilled, PlusOutlined } from '@ant-design/icons-vue';

import { createApiUrl, firstError, getBase64, isEmpty } from '@/utils/utils';
import { request } from '@/utils/request';
import { useCapacity } from '@/views/auto/utils';
import { useState } from '@/store';
import { useEcho } from '@/utils/echo';
import { upload } from '@/utils/cos';


const xreg = /^[云京使冀吉宁川新晋桂沪津浙渝湘琼甘皖粤苏蒙藏豫贵赣辽鄂闽陕青领鲁黑A-Z]{1}[A-Z]{1}(([0-9]{5,6}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/;
const creg = /^[云京使冀吉宁川新晋桂沪津浙渝湘琼甘皖粤苏蒙藏豫贵赣辽鄂闽陕青领鲁黑A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4,5}[A-HJ-NP-Z0-9挂学警港澳]{1}$/;

const provinceStr = '京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领';

type AutoFormData = {
  capacityUnit: string;
  // 基础数据
  enterpriseId: number;
  enterpriseName: string;
  vehicleName: string;
  vehicleNoPefix: string;
  permitUrl: string; //
  permitUrlKey: string; // 图片地址
  licensePlate: string; // 车牌号
  vehicleBrandName: string;
  vehicleBrand: number; // 车辆品牌
  model: string; // 车辆型号
  owner: string; // 车辆归属人
  vin: string; // 车辆识别代码
  displacement: string; // 汽车排量
  oilConsumption: string; // 汽车油耗
  // 限制数据
  afterQuotaRule: null;
  state: boolean;
  vehicleBrandLogo: string;
  ocrFlag: number;
  singleLimitAmountStatus: boolean;
  dayLimitAmountStatus: boolean;
  dayLimitAmount: number | string;
  singleLimitAmount: number | string;
  carType: number;
  vehicleGroup: number | null;
  vehicleGroupName: string;
  energyType: number;
  supplementRestrictId: number | null;
  oilProhibitFlag: number,
  qyNos: Array<any>,
  qyNames: Array<any>,
  cyNos: Array<any>,
  cyNames: Array<any>,
  trqNos: Array<any>,
  trqNames: Array<any>,
  prohibitOilNos: string,
  prohibitOilNames: string,
  compulsoryInsuranceUrl: string,
  title?: string,
  canEditOilConsumption?: boolean // 油耗是否可编辑
};

export default defineComponent({
  name: 'EditorDrawer',
  components: {
    QuestionCircleFilled,
    PlusOutlined,
  },
  props: {
    brandList: { type: Object, default: null, require: true },
    // "energyType", value = "车辆能源类型 1加油车 2充电车"
    energyType: {
      type: Number,
      default: 1,
    },
    vehicleGroupList: {
      type: Array as PropType<{ id: number, groupName: string}[]>,
      default: () => ([]),
    },
  },
  emits: ['complete', 'createComplete', 'loadedDataAfterClick'],
  setup(props, ctx) {
    let autoId = 0;
    const store = useStore();
    const state = useState();
    const recordData = ref<{ enterpriseId: number, vehicleNo: string, num?: number, licensePlate?: string, vin?: string}>({ enterpriseId: 0, vehicleNo: '' });
    const capacityState = useCapacity();
    const visible = ref(false);

    const drawerVisible = ref(false);
    const actionType = ref('');
    const editingBlock = ref<any>(null);
    const oilNosList = reactive<{ qy: Array<{ oilNo: number, oilName: string}>, cy: Array<{ oilNo: number, oilName: string}>, trq: Array<{ oilNo: number, oilName: string}> }>({ qy: [], cy: [], trq: [] });

    const basicEditing = computed(() => actionType.value === 'create' || editingBlock.value === 'basic');
    const limitEditing = computed(() => actionType.value === 'create' || editingBlock.value === 'limit');

    const getDefaultFormData = (): AutoFormData => ({
      enterpriseName: '',
      enterpriseId: state.enterprise.value?.enterpriseId,
      capacityUnit: 'T',
      // 基础数据
      vehicleName: '',
      vehicleNoPefix: '京',
      permitUrl: '',
      permitUrlKey: '',
      licensePlate: '',
      vehicleBrandName: '',
      vehicleBrand: 0,
      model: '', // 车辆型号
      owner: '', // 车辆归属人
      vin: '', // 车辆识别代码
      displacement: '', // 汽车排量
      oilConsumption: '', // 汽车油耗
      afterQuotaRule: null, // 限制数据
      state: true,
      vehicleBrandLogo: '',
      ocrFlag: 0,
      singleLimitAmountStatus: false,
      dayLimitAmountStatus: false,
      dayLimitAmount: '',
      singleLimitAmount: '',
      carType: 1,
      vehicleGroup: null,
      vehicleGroupName: '',
      energyType: props.energyType,
      supplementRestrictId: null,
      oilProhibitFlag: 0,
      qyNos: [],
      qyNames: [],
      cyNos: [],
      cyNames: [],
      trqNos: [],
      trqNames: [],
      prohibitOilNos: '',
      prohibitOilNames: '',
      compulsoryInsuranceUrl: '',
      title: '',
      canEditOilConsumption: false,
    });

    const drawerTitle = computed(() => {
      if (actionType.value === 'create') return '新增车辆';
      if (!editingBlock.value) return '车辆详情';
      return '车辆编辑';
    });

    const provinceTemp = provinceStr.split('');

    const formRef = ref();
    const formData = reactive<AutoFormData>({ ...getDefaultFormData() });

    // = ---------------------------- = 油补列表 = ---------------------------- =
    const capacityListT = ref<{ value: string; label: string }[]>([]);
    const capacityListL = ref<{ value: string; label: string }[]>([]);

    watch(() => formData.capacityUnit, (val: string) => {
      if (basicEditing.value) {
        if (formData.displacement.indexOf(val) === -1) {
          formData.displacement = val === 'T' ? capacityListT.value[0].value : capacityListL.value[0].value;
          formData.oilConsumption = capacityState.capacityList.value.reduce((prev, item) => (item.displacement === formData.displacement ? item.oilConsumption : prev), '');
        }
      }
    });

    watchEffect(() => {
      formData.capacityUnit = formData.displacement && formData.displacement.includes('L') ? 'L' : 'T';
    });

    const handleCapacity = (val: any) => {
      formData.oilConsumption = capacityState.capacityList.value.reduce((prev, item) => (item.displacement === val ? item.oilConsumption : prev), '');
    };

    // = ---------------------------- = 行驶证上传 = ---------------------------- =
    const drivingLicenseUploading = ref(false);
    const drivingLicenseFile = ref('');
    const onChooseDrivingLicenseImage = async (event: Event) => {
      const ele = (event.target as HTMLInputElement);
      if (!ele.files!.length) return;

      const file = ele.files![0];

      // 上传图片
      let res: any;
      drivingLicenseUploading.value = true;
      try {
        const fd = new FormData();
        fd.append('file', file);
        res = await request.post(createApiUrl('/newlinkSass/vehicle/upload/ocr-license-image'), fd, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      } finally {
        drivingLicenseUploading.value = false;
      }

      const rd = res.data;
      const outputVolume = rd.outputVolume ? rd.outputVolume.replace(/\s/g, '') : '';
      Object.assign(formData, {
        ...rd,
        vehicleBrand: rd.vehicleBrand ? +rd.vehicleBrand : '',
        vehicleNoPefix: rd.licensePlate ? rd.licensePlate.substr(0, 1) : '',
        licensePlate: rd.licensePlate ? rd.licensePlate.substr(1, rd.licensePlate.length - 1) : '',
        model: rd.vehicleModel,
        owner: rd.vehicleOwner,
        displacement: /^\d+(\.\d{0,2})?[LT]$/.test(outputVolume) ? outputVolume : '',
        oilConsumption: capacityState.capacityList.value.reduce((prev, item) => (item.displacement === outputVolume ? item.oilConsumption : prev), ''),
      });
    };

    let oldLicensePlate = '';
    const oilProhibitValidator = (rule:any, value:any) => {
      if (!value && value !== 0) return Promise.reject('请选择油品限制');
      if (value && !formData.qyNos.length && !formData.cyNos.length && !formData.trqNos.length) return Promise.reject('请选择支持加注的油品');
      return Promise.resolve(); 
    };
    const formRules = {
      vehicleName: [{ required: true, message: '请输入车辆名称', trigger: 'blur' }],
      licensePlate: [
        {
          validator: async (rule: any, value: any) => {
            if (formData.vin && !value) return Promise.resolve();
            const vehicleNumber = formData.vehicleNoPefix + value;
            if (vehicleNumber === oldLicensePlate) return Promise.resolve();

            if (!creg.test(vehicleNumber) && !xreg.test(vehicleNumber)) {
              return Promise.reject('车牌号码输入有误');
            }

            const d = await request.get(createApiUrl('/newlinkSass/vehicle/check/licensePlate'), { params: { licensePlate: vehicleNumber, enterpriseId: formData.enterpriseId, energyType: props.energyType } });
            return d.data ? Promise.resolve() : Promise.reject('车辆已存在');
          },
          trigger: 'change',
        },
      ],
      displacement: [
        { required: store.getters['User/isScheduleModel'], message: '请选择排量', trigger: 'blur' },
      ],
      oilConsumption: [
        { 
          validator: (rule: any, value: any) => {
            if (!store.getters['User/isScheduleModel'] || !formData.canEditOilConsumption) return Promise.resolve();
            if (!isEmpty(value)) return Promise.reject('请输入油耗（L/100km）');
            if (parseNumberOrZero(value) > 0) return Promise.resolve();
            return Promise.reject('油耗必须大于 0');
          }, 
          trigger: 'blur',
        },
      ],
      singleLimitAmount: [{
        validator: (rule: any, value: any) => {
          if (!formData.singleLimitAmountStatus) return Promise.resolve();
          return ((parseNumberOrZero(value) <= 0) ? Promise.reject('请输入正数金额') : Promise.resolve());
        },
        trigger: 'change',
      }],
      dayLimitAmount: [{
        validator: (rule: any, value: any) => {
          if (!formData.dayLimitAmountStatus) return Promise.resolve();
          return ((parseNumberOrZero(value) <= 0) ? Promise.reject('请输入正数金额') : Promise.resolve());
        },
        trigger: 'change',
      }],
    };

    // = ---------------------------- = 获取车辆信息 = ---------------------------- =
    const oilProhibitChange = (e: any) => {
      const { value } = e.target;
      if (!value) {
        formRef.value.clearValidate('oilProhibitFlag');
        formData.qyNos = [];
        formData.qyNames = [];
        formData.cyNos = [];
        formData.cyNames = [];
        formData.trqNos = [];
        formData.trqNames = [];
      }
    };
    const qyChange = (value:any) => {
      const qyNames: Array<any> = [];
      oilNosList.qy.forEach((item:any) => {
        if (value.includes(item.oilNo)) qyNames.push(item.oilName);
      });
      formData.qyNames = qyNames;
    };

    const cyChange = (value: any) => {
      const cyNames: Array<any> = [];
      oilNosList.cy.forEach((item:any) => {
        if (value.includes(item.oilNo)) cyNames.push(item.oilName);
      });
      formData.cyNames = cyNames;
    };

    const trqChange = (value: any) => {
      const trqNames: Array<any> = [];
      oilNosList.trq.forEach((item:any) => {
        if (value.includes(item.oilNo)) trqNames.push(item.oilName);
      });
      formData.trqNames = trqNames;
    };

    const handleProhibit = (data: any) => {
      const { prohibitOilNos, prohibitOilNames } = data;
      const oilNos = prohibitOilNos ? prohibitOilNos.split(',') : []; 
      const oilNames = prohibitOilNames ? prohibitOilNames.split(',') : []; 
      return oilNos.reduce((pre: any, item: any, index: number) => {
        if (!item) return pre;
        const gasFlag = oilNosList.qy.findIndex((flag:any) => flag.oilNo === Number(item));
        const dieselFlag = oilNosList.cy.findIndex((flag:any) => flag.oilNo === Number(item));
        const natgasFlag = oilNosList.trq.findIndex((flag:any) => flag.oilNo === Number(item));
        if (gasFlag !== -1) {
          pre.qyNos.push(Number(item));
          pre.qyNames.push(oilNames[index]);
        } 
        if (dieselFlag !== -1) {
          pre.cyNos.push(Number(item));
          pre.cyNames.push(oilNames[index]);
        }
        if (natgasFlag !== -1) {
          pre.trqNos.push(Number(item));
          pre.trqNames.push(oilNames[index]);
        }
        return pre;
      }, { qyNos: [], qyNames: [], cyNos: [], cyNames: [], trqNos: [], trqNames: [] });
    };

    // 自定义上传图片
    const fileList = ref<Array<{ uid: string, status: string, url: string }>>([]);
    const customRequest = async (File: any) => {
      const path: any = await upload(File.file);
      formData.compulsoryInsuranceUrl = decodeURI(path);
      fileList.value = [{
        uid: File.file.uid,
        status: 'done',
        url: decodeURI(path),
      }];
    };

    // 删除图片
    const removeRequest = () => {
      formData.compulsoryInsuranceUrl = '';
      fileList.value = [];
    };

    // 预览图片
    const previewVisible = ref<boolean>(false);
    const previewImage = ref<string | undefined>('');
    const handlePreview = async (file: any) => {
      if (!file.url && !file.preview) {
        file.preview = (await getBase64(file.originFileObj)) as string;
      }
      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
    };

    // 预览图片关闭
    const handleCancel = () => {
      previewVisible.value = false;
    };

    const loadAutoDetailById = async (id: number) => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/vehicle/vehicle-find-detail'), { params: { id } });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      const { data } = res;

      oldLicensePlate = data.licensePlate;
      const prohibitData = handleProhibit(data); 
      Object.assign(formData, {
        ...data,
        vehicleGroup: data.vehicleGroup || null,
        vehicleNoPefix: data.licensePlate ? data.licensePlate.substr(0, 1) : '',
        licensePlate: data.licensePlate ? data.licensePlate.substr(1, data.licensePlate.length - 1) : '',
        vehicleBrand: data.vehicleBrand ? +data.vehicleBrand : '',
        singleLimitAmountStatus: !!data.singleLimitAmountStatus,
        dayLimitAmountStatus: !!data.dayLimitAmountStatus,
        dayLimitAmount: `${data.dayLimitAmount ? data.dayLimitAmount / 100 : ''}`,
        singleLimitAmount: `${data.singleLimitAmount ? data.singleLimitAmount / 100 : ''}`,
        state: data.vehicleState === 'OPEN',
        supplementRestrictId: data.supplementRestrictId,
        compulsoryInsuranceUrl: data.compulsoryInsuranceUrl || '',
        canEditOilConsumption: !!data.canEditOilConsumption,
        ...prohibitData,
      });
      fileList.value = data.compulsoryInsuranceUrl ? [{ uid: '1', status: 'done', url: data.compulsoryInsuranceUrl }] : [];
    };

    const echo = useEcho();
    echo('doWith', async ({ action, id, record }: {action: string, id: number, record: any}) => {
      actionType.value = action;
      recordData.value = record;
      drawerVisible.value = true;
      if (action === 'create') {
        const d = await request.get(createApiUrl('/newlinkSass/vehicle/get/default/name'));
        formData.vehicleName = d.data;
      } else {
        autoId = id;
        loadAutoDetailById(id);
      }
    });

    const onCloseDrawer = () => {
      Object.assign(formData, getDefaultFormData());
      fileList.value = [];
      drawerVisible.value = false;
      editingBlock.value = null;
    };

    const onRefresh = () => {
      onCloseDrawer();
      ctx.emit('complete');
    };

    // = ---------------------------- = 提交新建/修改数据 = ---------------------------- =
    /**
     * 无论修改分块，还是整体创建都做整个表单校验
     */
    const onSubmit = async () => {
      if (props.energyType === 2 && !formData.licensePlate) {
        return message.error('请填写车牌号码。');
      }
      if (props.energyType === 1 && !formData.licensePlate && !formData.vin) {
        return message.error('请填写车牌号码或车辆识别代号，必填其中一项。');
      }
      if (formData.ocrFlag && !formData.licensePlate) {
        return message.error('如需开启车辆验证请填写车牌号码。');
      } 
      try {
        await formRef.value.validate();
      } catch (e: Error & any) {
        return message.error(firstError(e));
      }

      const { qyNos, qyNames, cyNames, cyNos, trqNames, trqNos, ...others } = formData;

      const baseParams: any = {
        ...others,
        prohibitOilNos: qyNos.concat(cyNos).concat(trqNos).join(','),
        prohibitOilNames: qyNames.concat(cyNames).concat(trqNames).join(','),
        licensePlate: formData.licensePlate ? (formData.vehicleNoPefix + formData.licensePlate) : undefined,
        dayLimitAmountStatus: formData.dayLimitAmountStatus ? 1 : 0,
        dayLimitAmount: formData.dayLimitAmount ? +formData.dayLimitAmount * 100 : 0,
        singleLimitAmountStatus: formData.singleLimitAmountStatus ? 1 : 0,
        singleLimitAmount: formData.singleLimitAmount ? +formData.singleLimitAmount * 100 : 0,
        state: formData.state ? 'OPEN' : 'CLOSE',
        vehicleGroupName: others.vehicleGroup ? props.vehicleGroupList.find(c => c.id === others.vehicleGroup)?.groupName : '未分组',
      };

      let res: any;
      const call = actionType.value === 'create'
        ? () => request.post(createApiUrl('/newlinkSass/vehicle/addVehicleInfo'), baseParams)
        : editingBlock.value === 'basic'
          ? () => request.put(createApiUrl('/newlinkSass/vehicle/editVehicleInfo'), baseParams)
          : () => request.put(createApiUrl('/newlinkSass/vehicle/editVehicleRestrictConf'), baseParams);

      try {
        res = await call();
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      if (actionType.value === 'create') {
        message.success(`${baseParams.vehicleName}车辆添加成功!`);
        onCloseDrawer();
        ctx.emit('complete');
        ctx.emit('createComplete', {
          id: res.data.id,
          enterpriseId: baseParams.enterpriseId,
          vehicleNo: res.data.vehicleNo,
          licensePlate: res.data.licensePlate,
          vehicleBrandLogo: res.data.vehicleBrandLogo,
        });
      } else {
        loadAutoDetailById(autoId);
        message.success(`${baseParams.vehicleName}车辆修改成功!`);
        editingBlock.value = null;
        ctx.emit('complete');
      }
    };

    // = ---------------------------- = 获取油补制度 = ---------------------------- =
    const systemList: any = ref([]);
    const loadSystemList = async () => {
      let res: any;
      try {
        res = await request.post(createApiUrl('/newlinkSass/vehicle/restrict/get-restrict-list'), { enterpriseId: formData.enterpriseId });
        systemList.value = res.data;
      } catch (e: Error & any) {
        return message.error(e.message);
      }
    };

    // = ----------------- 车辆删除 ----------------------- =
    const closeModel = () => {
      visible.value = false;
      onRefresh();
    };

    const sureChangeModelClick = async () => {
      request.put(createApiUrl('/newlinkSass/vehicle/del/vehicle'), { vehicleNo: recordData.value.vehicleNo })
        .then(() => {
          message.success('车辆删除成功');
          ctx.emit('complete');
        });
      closeModel();
    };

    const onCancelEditBlock = () => {
      editingBlock.value = null;
      formRef.value.clearValidate();
      loadAutoDetailById(autoId);
    };

    const cancelOnAdd = () => {
      fileList.value = [];
      drawerVisible.value = false;
    };

    const filterOption = (iv: string, option: any) => (option.children[0].children.toLowerCase().indexOf(iv.toLowerCase()) >= 0);

    const getOilList = async (type?: number) => {
      try {
        const res = await request.get(createApiUrl('/newlinkSass/enterprise/getCanUsedOilNos'), { params: { enterpriseId: formData.enterpriseId } });
        if (res.code !== 200) return message.error(res.message);
        Object.assign(oilNosList, { ...res.data });
        if (type && actionType.value === 'view') {
          const prohibitData = handleProhibit(formData); 
          Object.assign(formData, { ...prohibitData });
        }
      } catch (e: Error & any) {
        return message.error(e.message);
      }
    };

    watch(() => formData.enterpriseId, () => {
      oilProhibitChange({ target: { value: 0 } });
      getOilList(1);
    });

    onMounted(async () => {
      await capacityState.loadCapacity();

      capacityListT.value = capacityState.capacityList.value
        .filter(item => item.displacement.indexOf('T') !== -1)
        .map(item => ({ value: item.displacement, label: item.displacement }));

      capacityListL.value = capacityState.capacityList.value
        .filter(item => item.displacement.indexOf('L') !== -1)
        .map(item => ({ value: item.displacement, label: item.displacement }));

      loadSystemList();
      getOilList();
    });

    return {
      /* 全局信息 */
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      capacityListT,
      capacityListL,
      systemList,

      drawerTitle,
      editingBlock,
      basicEditing,
      limitEditing,

      provinceTemp,
      actionType,
      drawerVisible,

      drivingLicenseFile,
      drivingLicenseUploading,
      onChooseDrivingLicenseImage,

      formRef,
      formData,
      formRules,

      /* 方法 */
      onSubmit,
      onCancelEditBlock,
      cancelOnAdd,
      onCloseDrawer,
      filterOption,

      onRefresh,
      recordData,
      visible,
      closeModel,
      sureChangeModelClick,
      oilNosList,
      oilProhibitValidator,
      qyChange,
      cyChange,
      trqChange,
      oilProhibitChange,
      customRequest,
      removeRequest,
      handlePreview,
      previewImage,
      previewVisible,
      handleCancel,
      fileList,
      handleCapacity,
    };
  },
});

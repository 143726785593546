
import { defineComponent, reactive, ref, watch } from 'vue';
import { message } from 'ant-design-vue';
import { request } from '@/utils/request';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';

export default defineComponent({
  name: 'BatchSetSystem',
  emits: ['complete', 'close'],
  setup(props, ctx) {
    const visible = ref(false);

    const selectList = ref<{ id: number; }[]>([]);

    const selectedStaff = ref<{ id: number; }[]>([]);
    const selectedStaffKey = ref<number[]>([]);

    const defaultData: {
      restrictId?: number | null;
      vehicleIds: number[];
      type: number;
    } = {
      restrictId: null,
      vehicleIds: [],
      type: 1,
    };

    const formData = reactive({
      ...defaultData,
    });

    const rules = {
      restrictId: [{ type: 'number', required: true, message: '请选择车辆制度', trigger: 'change' }],
    };

    watch(selectedStaff, val => {
      formData.vehicleIds = val.map(item => item.id);
      selectedStaffKey.value = val.map(itm => itm.id);
    });

    // = ---------------------------- = 获取油补制度 = ---------------------------- =
    const systemList: any = ref([]);
    const loadSystemList = async () => {
      let res: any;
      try {
        res = await request.post(createApiUrl('/newlinkSass/vehicle/restrict/get-restrict-list'));
        systemList.value = res.data;
      } catch (e: Error & any) {
        return message.error(e.message);
      }
    };

    // 修改动作入口
    const change = (recordList: any[] = []) => {
      Object.assign(formData, defaultData);
      selectedStaff.value = recordList.map(item => ({ id: item.id }));

      visible.value = true;
      loadSystemList();
    };

    const submiting = ref(false);
    const editorFormRef = ref<any>(null as any);
    const onSubmit = async () => {
      try {
        await editorFormRef.value.validate();

        if (submiting.value) return;
        submiting.value = true;
        await request.post(createApiUrl('/newlinkSass/vehicle/restrict/bind-vehicles-batch'), formData);
      } catch (e: Error & any) {
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      } finally {
        submiting.value = false;
      }

      message.success('批量设置制度完成');
      visible.value = false;
      ctx.emit('complete');
    };

    const closeModal = () => {
      formData.restrictId = null;
      ctx.emit('complete');
    };

    const filterOption = (input: string, option: any) => option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    return {
      selectList,
      closeModal,

      selectedStaff,
      selectedStaffKey,

      visible,
      change,

      submiting,
      formData,
      rules,
      editorFormRef,
      onSubmit,

      filterOption,
      systemList,
    };
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vehicle_manage = _resolveComponent("vehicle-manage")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_vehicle_group = _resolveComponent("vehicle-group")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "utils__pageContainer auto_page" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_tabs, {
        activeKey: _ctx.activeKey,
        "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
        class: "card_tab"
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_component_a_tab_pane, {
            key: 1,
            tab: "燃油车管理"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_vehicle_manage, {
                "energy-type": 1,
                "active-key": _ctx.activeKey,
                "vehicle-group-list": _ctx.vehicleGroupList
              }, null, 8, ["active-key", "vehicle-group-list"])
            ]),
            _: 1
          })),
          (_ctx.enterprise.energyTypes.includes(4))
            ? (_openBlock(), _createBlock(_component_a_tab_pane, {
                key: 2,
                tab: "新能源车管理"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_vehicle_manage, {
                    "energy-type": 2,
                    "active-key": _ctx.activeKey,
                    "vehicle-group-list": _ctx.vehicleGroupList
                  }, null, 8, ["active-key", "vehicle-group-list"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_openBlock(), _createBlock(_component_a_tab_pane, {
            key: 3,
            tab: "车辆分组管理"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_vehicle_group, {
                "energy-type": 3,
                "active-key": _ctx.activeKey
              }, null, 8, ["active-key"])
            ]),
            _: 1
          }))
        ]),
        _: 1
      }, 8, ["activeKey"])
    ]),
    _: 1
  }))
}
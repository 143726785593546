
import { defineComponent, reactive, ref } from 'vue';
import { message, Modal } from 'ant-design-vue';
import { request } from '@/utils/request';
import { createApiUrl, firstError, isRepeat, isValidateError } from '@/utils/utils';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons-vue';


export default defineComponent({
  name: 'CreateGroup',
  components: { PlusCircleOutlined, MinusCircleOutlined },
  emits: ['complete'],
  setup(props, ctx) {
    const title = ref('添加分组');
    const type = ref('add');
    const visible = ref(false);
    const formRef = ref();
    const submiting = ref(false);
    const formData = reactive<{list: Array<any>}>({ list: [{ groupName: '' }] });

    const handle = (val: string, obj?: Record<string, any>) => {
      visible.value = true;
      type.value = val;
      if (val === 'edit') {
        title.value = '修改分组';
        formData.list = [{ ...obj }];
      }
    };

    // 添加记录
    const addRecord = (record:any, index: number) => {
      if (formData.list.length > 9) return message.warning('批量添加最多10个');
      formData.list.splice(index + 1, 0, { groupName: '' });
    };

    // 删除记录
    const deleteRecord = (record: any, index: number) => {
      formData.list.splice(index, 1);
    };

    // 关闭
    const close = (flag = false) => {
      formRef.value.resetFields();
      visible.value = false;
      type.value = 'add';
      title.value = '添加分组';
      formData.list = [{ groupName: '' }];
      ctx.emit('complete', flag);
    };
    
    // 确定
    const onSubmit = async () => {
      if (formData.list.length <= 0) return message.error('请添加分组');
      try {
        await formRef.value.validate();
        submiting.value = true;
        let url = '';
        const params: { groupNames?: Array<string>, id?: string, groupName?: string } = {};
        if (type.value === 'add') {
          url = '/newlinkSass/vehicleGroup/batchAdd';
          const groupNames = formData.list.map(item => item.groupName);
          if (isRepeat(groupNames)) return message.error('分组名称有重复的～');
          params.groupNames = groupNames;
        } else {
          url = '/newlinkSass/vehicleGroup/update';
          params.id = formData.list[0].id;
          params.groupName = formData.list[0].groupName;
        }
        await request.post(createApiUrl(url), { ...params });
        message.success(type.value === 'add' ? `已成功创建${params.groupNames?.length}个新分组` : '分组名称已修改成功');
        close(true);
      } catch (e: Error & any) {
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      } finally {
        submiting.value = false;
      }
    };  

    return {
      formData,
      formRef,
      submiting,
      addRecord,
      deleteRecord,
      type,
      visible,
      onSubmit,
      close,
      handle,
      title,
    };
  },
});

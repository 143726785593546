import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "removeStaffModal__content",
  style: {"height":"auto"}
}
const _hoisted_2 = { style: {"padding":"0 20px"} }
const _hoisted_3 = { class: "text-error" }
const _hoisted_4 = { class: "removeStaffModal__select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    class: "removeStaffModal",
    width: "480px",
    title: "批量移除员工",
    "destroy-on-close": true,
    onConfirmLoading: _ctx.submiting,
    onOk: _ctx.onSubmit,
    onCancel: _ctx.closeModel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, [
          _createTextVNode("确定从车辆 "),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.autoDetail.licensePlate || _ctx.autoDetail.vin || ''), 1),
          _createTextVNode(" 移除以下员工 ？")
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.staffList, (item, $index) => {
            return (_openBlock(), _createBlock(_component_a_tag, {
              key: item.vehicleUserRelId,
              closable: "",
              onClose: ($event: any) => (_ctx.onClose($index))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.userName), 1)
              ]),
              _: 2
            }, 1032, ["onClose"]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "onConfirmLoading", "onOk", "onCancel"]))
}

import { defineComponent, computed, reactive, ref, onMounted, toRefs, createVNode, watch, isRef, isReactive, PropType, getCurrentInstance } from 'vue';
import { message, Modal } from 'ant-design-vue';

import { formatWithIntl, noEmptyProp, dispatchDownload, insert } from '@/utils/illuminate';
import moment from 'moment';

import { CheckCircleOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { UnwrapNestedRefs } from '@vue/reactivity';
import type { TableColumn } from '@/components/Table';
import { createApiUrl } from '@/utils/utils';
import { request } from '@/utils/request';

import QuotaAdjustment from '@/components/QuotaAdjustment/modal.vue';

import MultipleQuotaAdjustmentModal from '@/components/MultipleQuotaAdjustment/Modal.vue';
import { AvailableQuotaData, MultipleQuotaAdjustmentProps, RegularQuotaData } from '@/components/MultipleQuotaAdjustment/utils';
import { useCurrentEnterpriseHook } from '@/store';
import { useCall } from '@/utils/echo';
import AutoEditComponent from './parts/ConfirmModal.vue';
import ActionDrawer from './ActionDrawer.vue';
import BatchSetSystem from './parts/BatchSetSystem.vue';
import ImportAutoModal from './parts/ImportAutoModal.vue';

export default defineComponent({
  components: {
    MultipleQuotaAdjustmentModal,
    QuotaAdjustment,
    ActionDrawer,
    AutoEditComponent,
    ImportAutoModal,
    BatchSetSystem,
  },
  props: {
    // 1 燃油车管理 2 新能源车管理
    energyType: {
      type: Number,
      default: 1,
    },
    activeKey: {
      type: Number,
      default: 1,
    },
    vehicleGroupList: {
      type: Array as PropType<{ id: number, groupName: string}[]>,
      default: () => ([]),
    },
  },
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const store = useStore();
    useCurrentEnterpriseHook('');
    const router = useRouter();

    const isStateLoading = ref(false);
    const dataPage = ref([]); // 当前页面数据
    const dataState = ref(false); // 请求数据状态
    const allDataState = ref(false); // 全选状态
    let selectState: UnwrapNestedRefs<{
      selectedRowKeys: any[];
      selectedRows: any[];
      hideDefaultSelections: boolean;
      onChange: (selectedRowKeys: any[], selectedRows: any[]) => void,
    }>;

    const onSelectChange = (selectedRowKeys: any[], selectedRows: any[]) => {
      selectState.selectedRows = selectedRows;
      const arr: any = [];
      if (allDataState.value && dataState.value) {
        dataState.value = false;
        dataPage.value.map((item: any) => { arr.push(item.id); });
        selectState.selectedRowKeys = arr;
      } else {
        selectState.selectedRowKeys = selectedRowKeys;
      }
    };

    selectState = reactive({
      selectedRowKeys: [],
      selectedRows: [],
      hideDefaultSelections: true,
      onChange: onSelectChange,
    });

    /* 抽屉组件显示与隐藏 */
    const drawerVisible = ref(false);
    const brandList = ref([]);

    const filterParams = reactive({
      keyword: '',
      vin: '',
      bindStatus: '',
      ocrStatus: '',
      userCode: undefined,
      carType: '',
      vehicleGroup: '',
    });

    const searchList = computed(() => [
      { 
        label: '车牌',
        name: 'keyword',
        type: 'input',
        allowClear: true,
        placeholder: '车牌号码/车辆名称',
      },
      { 
        label: '车牌识别代号',
        name: 'vin',
        type: 'input',
        allowClear: true,
        placeholder: '车牌识别代号',
      },
      { 
        label: '公司',
        name: 'currentEnterpriseId',
        type: 'currentEnterprise',
        disableInOpen: true,
        withDefaultAll: true,
      },
      { 
        label: '车辆验证',
        name: 'ocrStatus',
        type: 'select',
        sel_data: [
          { key: '', name: '全部' },
          { key: 1, name: '开启' },
          { key: 0, name: '关闭' },
        ],
      },
      { 
        label: '员工',
        name: 'userCode',
        type: 'member',
        placeholder: '员工',
        status: 'ALL',
      },
      { 
        label: '绑定员工',
        name: 'bindStatus',
        type: 'select',
        sel_data: [
          { key: '', name: '全部' },
          { key: 1, name: '有' },
          { key: 0, name: '无' },
        ],
      },
      { 
        label: '车类型',
        name: 'carType',
        type: 'select',
        sel_data: [
          { key: '', name: '全部' },
          { key: 1, name: '私车' },
          { key: 0, name: '公车' },
        ],
      },
      { 
        label: '车辆分组',
        name: 'vehicleGroup',
        type: 'select',
        dropdownStyle: { maxHeight: '200px', overflow: 'auto' },
        fieldNames: { label: 'groupName', value: 'id' },
        showSearch: true,
        filterOption: (input: string, option: any) => option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0,
        sel_data: [
          { id: '', groupName: '全部' },
          { id: 0, groupName: '未分组' },
          ...props.vehicleGroupList,
        ],
      },

    ]);

    // 加载数据方法 必须为 Promise 对象
    const loadTableData = (parameter: any) => new Promise<void>((resolve, reject) => {
      console.log(parameter, 'parameter');
      request.get(
        createApiUrl('/newlinkSass/vehicle/vehicle-find-vehicle-list'),
        // "energyType", value = "车辆能源类型 1加油车 2充电车"
        { params: { ...parameter, energyType: props.energyType, ...noEmptyProp(filterParams), enterpriseId: store.state.User!.currentEnterpriseId }, noEnterpriseId: true },
      )
        .then((res: any) => {
          const { data } = res;
          data.list.map((item: any) => {
            item.stateBoolean = item.state === 'OPEN';
          });
          isStateLoading.value = false;
          dataPage.value = data.list; // 当前Table数据
          dataState.value = true; // 数据第一次加载
          resolve(data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const quotaAdjustRef = ref<any>(null);
    const onQuotaAdjust = (record: any) => {
      quotaAdjustRef.value.showModal({ type: 'car', val: record });
    };

    const tableRef = ref<any>(null);
    const onRefreshTable = () => {
      allDataState.value = false;
      tableRef.value.refresh(true);
    };
    watch(() => props.activeKey, newV => {
      if (newV === props.energyType) {
        onRefreshTable();
      }
    });
    watch(() => filterParams.keyword, newValue => {
      if (newValue === '') {
        onRefreshTable();
      }
    });

    const handleSearch = () => {
      
      console.log(filterParams, store.state.User!.currentEnterpriseId);
      onRefreshTable();
    };

    const tableColumns = computed<TableColumn[]>(() => {
      const basic: TableColumn[] = [
        { title: '车辆名称', dataIndex: 'vehicleName', align: 'center', width: '130px' },
        { title: '车牌号码', dataIndex: 'licensePlate', slots: { customRender: 'licensePlate' }, align: 'center', width: '130px' },
        { title: '车辆分组', dataIndex: 'vehicleGroupName', align: 'center', width: '130px' },
        { title: '车类型', dataIndex: 'carType', slots: { customRender: 'carType' }, align: 'center', width: '130px' },
        { title: '车牌识别代号', dataIndex: 'vin', slots: { customRender: 'vin' }, align: 'center', width: '130px' },
        { title: '可用额度', dataIndex: 'availableAmount', customRender: ({ text, record }) => (record.quotaFlag === 0 ? formatWithIntl((text || 0) / 100) : '无限'), align: 'center', width: '130px' },
        { title: '员工人数', dataIndex: 'num', slots: { customRender: 'num' }, align: 'center', width: '130px' },
        { title: '所属公司', dataIndex: 'enterpriseName', align: 'center', width: '130px' },
        { title: '车辆验证', dataIndex: 'ocrFlag', slots: { customRender: 'ocrFlag' }, align: 'center', width: '130px' },
        { title: '状态', dataIndex: 'state', slots: { customRender: 'state' }, align: 'center', width: '130px' },
        { title: '操作', dataIndex: 'action', width: '170px', align: 'center', slots: { customRender: 'action' }, fixed: 'right' },
      ];

      if (store.getters['User/isScheduleModel']) {
        insert(basic, 2, { title: '员工姓名', dataIndex: 'userName', align: 'center', customRender: ({ text }) => text || '--', width: '130px' } as TableColumn);
      } else {
        insert(basic, 5, { title: '定期额度', dataIndex: 'quotaAmount', align: 'center', slots: { customRender: 'quotaAmount' }, width: '130px' } as TableColumn);
      }

      return basic;
    });

    function loadedDataAfterClick() {
      drawerVisible.value = !drawerVisible.value;
    }

    // 加载品牌列表
    const loadBandList = async () => {
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/vehicle/brand/list'));
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      brandList.value = res.data;
    };

    const call = useCall();
    const changeAutoState = (record: Record<string, any>) => {
      isStateLoading.value = true;
      record.stateBoolean = !record.stateBoolean;
      call('confirm', { type: 'state', title: '车辆状态修改', record, state: !record.stateBoolean });
    };

    const fnDrawerClose = (obj: Record<string, any>) => {
      Modal.confirm({
        title: '车辆新增成功！',
        cancelText: '稍后绑定',
        okText: '绑定员工',
        icon: createVNode(CheckCircleOutlined),
        content: (<div style={{ fontSize: '16px', padding: '8px 0px' }}>
          { obj.vehicleBrandLogo ? <img src={obj.vehicleBrandLogo} style={{ width: '30px', height: '30px' }} alt="Brand"/> : null }
          <em>{obj.licensePlate}</em>
        </div>),
        onOk() {
          console.info(obj);
          router.push({ name: 'auto.staff', params: { id: obj.id, enterpriseId: obj.enterpriseId } });
        },
      });
    };

    /* = ---------------------------- = 批量tiaozheng = ---------------------------- = */
    const multipleQuotaAdjustmentRef = ref<any>(null);
    const onSubmitAvailableQuota = async (data: AvailableQuotaData) => request.post(createApiUrl('/newlinkSass/quota/vr/batch/update/available/amount/V2.0'), {
      ...data, vehicleNoList: selectState.selectedRowKeys,
    });

    const onSubmitRegularQuota = async (data: RegularQuotaData) => request.post(createApiUrl('/newlinkSass/quota/vr/batch/editQuotaRuleInfo'), {
      ...data, vehicleNoList: selectState.selectedRowKeys,
    });

    const onAdjustmentQuotaCancel = () => {
      selectState.selectedRows = [];
      selectState.selectedRowKeys = [];
      onRefreshTable();
    };

    const onSubmitInfiniteQuota = async () => {
      Modal.confirm({
        title: '操作确认',
        content: `将已选${selectState.selectedRowKeys.length}辆车账户设置为”无限“额度，确认调整后将立刻生效。`,
        okText: '确认调整',
        onOk: async () => {
          const params = { vehicleNoList: selectState.selectedRowKeys };
          const res = await request.post(createApiUrl('/newlinkSass/quota/vr/batchOpenUnlimitedBalance'), { ...params });
          if (res && res.code === 200) { 
            message.success('操作成功');
            onAdjustmentQuotaCancel();
            multipleQuotaAdjustmentRef.value.onCancel();
          }
        },
      });
    };

    const onAddQuota = (record: any) => {
      if (selectState.selectedRowKeys.length <= 0) return message.error('请先选择车辆');

      multipleQuotaAdjustmentRef.value.adjustmentFor({
        addtional: `(已选 ${selectState.selectedRowKeys.length} 辆车)`,
        activeObject: 'vehicle',
        activeObjectName: '车辆',
        onCancel: onAdjustmentQuotaCancel,
        onComplete: onAdjustmentQuotaCancel,
        onSubmitInfiniteQuota,
        onSubmitAvailableQuota,
        onSubmitRegularQuota,
      } as MultipleQuotaAdjustmentProps);
    };

    // 批量设置制度
    const batchSetSystem = ref<any>(null as any);
    const onSetSystem = () => {
      if (selectState.selectedRowKeys.length <= 0) return message.error('请先选择车辆');
      batchSetSystem.value.change(selectState.selectedRows);
    };

    // 删除车辆
    const onDelCar = async () => {
      if (selectState.selectedRowKeys.length <= 0) return message.error('请先选择车辆');
      const ids = selectState.selectedRows.map(i => i.id);
      try {
        const params = { energyType: props.energyType, vehicleIdList: ids };
        const res = await request.post(createApiUrl('/newlinkSass/vehicle/batchDeleteCheck'), { ...params });
        let namesStr = '';
        if (res.data.bindLicensePlateList && res.data.bindLicensePlateList.length) {
          namesStr = res.data.bindLicensePlateList.filter((i: any) => i).join('、');
        }
        Modal.confirm({
          title: '删除车辆',
          content: `已选：${selectState.selectedRowKeys.length}辆；\n${namesStr ? `${namesStr}仍有绑定员工` : ''}是否继续删除？`,
          okText: '确认',
          onOk: async () => {
            const params = { energyType: props.energyType, vehicleIdList: ids };
            await request.post(createApiUrl('/newlinkSass/vehicle/batchDelete'), { ...params });
            message.success('操作成功');
            onAdjustmentQuotaCancel();
          },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      }
    };

    onMounted(() => {
      if (props.energyType === 1) instance?.$sensors.track('es_saas_car_page_view', { page_name: '车辆列表_燃油车管理页_浏览' });
      if (props.energyType === 2) instance?.$sensors.track('es_saas_car_new_energy_page_view', { page_name: '车辆列表_新能源车管理页_浏览' });
      loadBandList();
    });

    // 批量导入车辆
    const importAutoRef = ref<any>(null as any);
    const importTitle = ref<number>(1);
    const onImport = async (typ: number) => {
      importTitle.value = typ;
      importAutoRef.value.show({ energyType: props.energyType, ...noEmptyProp(filterParams), enterpriseId: store.state.User!.currentEnterpriseId });
    };

    // 导出
    const onExportTable = async () => {
      let res: any;
      try {
        const params = { energyType: props.energyType, ...noEmptyProp(filterParams), enterpriseId: store.state.User!.currentEnterpriseId };
        // "energyType", value = "车辆能源类型 1加油车 2充电车"
        res = await request.post(createApiUrl('/newlinkSass/vehicleExcel/export/vehicleList'), params, { responseType: 'blob', noEnterpriseId: true });
      } catch (e: Error & any) {
        console.log(e);
        return message.error(e.message);
      }

      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      });

      const url = URL.createObjectURL(blob);
      const dataTime = moment().format('YYYYMMDDhh:mm:ss');
      dispatchDownload(`车辆列表${dataTime}.xlsx`, url);
      // 释放之前创建的URL对象
      window.URL.revokeObjectURL(url);
    };
    return {
      importTitle,
      onExportTable,
      quotaAdjustRef,
      onQuotaAdjust,
      filterParams,
      tableRef,
      tableColumns,
      dataPage,
      dataState,
      allDataState,

      loadTableData,
      onRefreshTable,

      multipleQuotaAdjustmentRef,
      onAddQuota,

      onSelectChange,
      drawerVisible,
      brandList,
      selectState,

      changeAutoState,
      isStateLoading,
      loadedDataAfterClick,
      fnDrawerClose,

      importAutoRef,
      onImport,
      onSetSystem,
      batchSetSystem,
      searchList,
      handleSearch,
      onDelCar,
    };
  },
});

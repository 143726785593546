
import { defineComponent, reactive, ref, computed } from 'vue';
import { message } from 'ant-design-vue';
import { assignWithDefault, dispatchDownload } from '@/utils/illuminate';

import { useStore } from 'vuex';
import { useUtils } from '@/core/utils';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'ImportStaffModal',
  props: {
    energyType: {
      type: Number,
      default: 1,
    },
    // 1 批量导入车辆 2 导入车辆额度 3 批量删除车辆
    importTitle: {
      type: Number,
      default: 1,
    },
  },
  emits: ['complete'],
  setup(props: any, ctx) {
    const utils = useUtils();
    const visible = ref(false);
    const uploadComplete = ref(false);
    const step = ref(0);
    const store = useStore();
    const router = useRouter();
    const downloadParams = ref({}); // 下载导入车辆模板参数
    const batchCode = ref();
    const title = computed(() => {
      switch (props.importTitle) {
        case 1:
          return '批量导入车辆';
        case 2:
          return '导入车辆额度';
        case 3:
          return '批量删除车辆';
        default: 
          return '';
      }
    });
    const onDownload = async (downloadType = 0, importFailKey = '') => {
      let res: any;
      try {
        if (props.importTitle === 1) {
          res = await request.get(createApiUrl('/newlinkSass/excel/download/vehicle/template'), {
            responseType: 'blob',
            params: {
              downloadType,
              importFailKey,
              enterpriseId: store.state.User.enterprise?.enterpriseId,
            },
          });
        }
        if (props.importTitle === 2) {
          res = await request.post(createApiUrl('/newlinkSass/vehicleExcel/export/vehicleQuotaTemplate'), {
            ...downloadParams.value,
          }, { responseType: 'blob', noEnterpriseId: true });
        }
        if (props.importTitle === 3) {
          res = await request.post(createApiUrl('/newlinkSass/excel/batchDeleteVehicle/export'), {
            type: downloadType,
            importFailKey,
            enterpriseId: store.state.User.enterprise?.enterpriseId,
          }, { responseType: 'blob' });
        }
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      });

      const url = URL.createObjectURL(blob);
      const fileName = downloadType === 0 ? (props.importTitle === 3 ? '车辆删除模版.xlsx' : '车辆导入模版.xlsx') : '错误文件.xlsx';
      dispatchDownload(fileName, url);
      URL.revokeObjectURL(url);
    };

    const formData = reactive({
      file: '',
    });

    const defaultRes = {
      fail: 0,
      success: 0,
      importFailKey: '',
    };

    const uploadRes = reactive<{
      fail: number;
      importFailKey: string;
      success: number;
    }>({
      ...defaultRes,
    });

    /* = -------------------------------------------------- = 上传文件 = -------------------------------------------------- = */

    const uploading = ref(false);

    const onUpload = async (event: Event) => {
      const ele = (event.target as HTMLInputElement);
      if (!ele.files!.length) {
        return;
      }

      const file = ele.files![0];

      // 上传文件
      let res: any;
      
      uploading.value = true;
      step.value = 1;

      try {
        const fd = new FormData();
        fd.append('file', file);
        fd.append('enterpriseId', store.state.User.enterprise?.enterpriseId);
        fd.append('energyType', props.energyType);
        let api = '';
        switch (props.importTitle) {
          case 1:
            api = '/newlinkSass/excel/import/enterprise/vehicle';
            break;
          case 2:
            api = '/newlinkSass/vehicleExcel/import/vehicleQuotaAdjust';
            break;
          case 3:
            api = '/newlinkSass/excel/batchDeleteVehicle/import';
            break;
          default:
            api = '';
        }
        res = await request.post(createApiUrl(api), fd, {
          headers: { 'Content-Type': 'multipart/form-data' },
          timeout: 300000,
        });
        batchCode.value = res.code === 200;
      } catch (e: Error & any) {
        (props.importTitle === 1 || props.importTitle === 3) ? step.value = 0 : batchCode.value = false;
        return message.error(e.message);
      } finally {
        uploading.value = false;
      }

      formData.file = '';
      uploadComplete.value = true;
      assignWithDefault(uploadRes, res.data || {}, defaultRes);
    };

    /* = -------------------------------------------------- = 入口 = -------------------------------------------------- = */
    const show = (val: any) => {
      downloadParams.value = val;
      visible.value = true;
      formData.file = '';
      step.value = 0;
      batchCode.value = false;
      uploadComplete.value = false;
      Object.assign(uploadRes, defaultRes);
    };

    const onComfirm = () => {
      visible.value = false;
      ctx.emit('complete');
    };

    const onCancel = () => {
      visible.value = uploading.value || false;
      if (batchCode.value) ctx.emit('complete');
    };

    // 查看处理结果
    const viewResults = () => {
      router.push({
        name: 'system.action_log',
        params: { activeKey: 2 },
      });
    };
    return {
      title,
      batchCode,
      viewResults,
      ...utils,

      labelCol: { span: 7 },
      wrapperCol: { span: 14 },

      visible,
      formData,
      show,
      onComfirm,
      onCancel,

      step,
      uploading,
      uploadComplete,
      uploadRes,
      onUpload,

      onDownload,
    };
  },
});


import { defineComponent, ref, reactive } from 'vue';
import { message } from 'ant-design-vue';
import { QuestionCircleFilled } from '@ant-design/icons-vue';

import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';
import { useEcho } from '@/utils/echo';

export default defineComponent({
  name: 'AutoEditComponent',
  components: {
    QuestionCircleFilled,
  },
  props: {
    source: {
      type: String,
      default: '',
    },
  },
  emits: ['complete'],
  setup(props, ctx) {
    const echo = useEcho();
    const okText = ref('修改');
    const visible = ref(false);
    const type = ref('del');
    const title = ref('');
    const record = ref<any>({});
    const stateType = ref(false);
    const okButtonProps = { type: 'primary' };

    echo('confirm', ({ type: typeName, title: titleStr, record: recordData, state }: { type: string, title: string, record: any, state: boolean }) => {
      type.value = typeName;
      title.value = titleStr;
      record.value = recordData;

      if (typeName === 'del') {
        okText.value = '确认';
      }
      if (typeName === 'state' && state) {
        title.value = '启用车辆';
        okText.value = '启用';
      }
      if (typeName === 'state' && !state) {
        title.value = '停用该车辆';
        okText.value = '停用';
      }
      stateType.value = state;
      visible.value = true;
    }, props.source);

    const closeModel = () => {
      visible.value = false;
      ctx.emit('complete');
    };

    const sureChangeModelClick = async () => {
      const state = stateType.value ? 2 : 1;
      if (type.value === 'del') {
        request.put(createApiUrl('/newlinkSass/vehicle/del/vehicle'), { vehicleNo: record.value.vehicleNo })
          .then(() => {
            message.success('车辆删除成功');
            ctx.emit('complete');
          });
        closeModel();
        return false;
      }
      request.put(createApiUrl('/newlinkSass/vehicle/update/statue'), { vehicleNo: record.value.vehicleNo, type: state })
        .then(() => {
          message.success('车辆状态修改成功');
          ctx.emit('complete');
        });
      closeModel();
    };

    return {
      visible,
      title,
      record,
      okButtonProps,
      okText,

      closeModel,
      stateType,
      sureChangeModelClick,
      type,
    };
  },
});

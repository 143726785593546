<template>
  <div class="utils__pageContainer busniessCard-page">
    <a-card class="businessCard-wrap">
      <template #title>
        <router-link to="/main/overview"><LeftOutlined /></router-link> 企业卡
      </template>
      <div class="form-content">
        <div class="form-wrap">
          <div class="card-form">
            <a-form class="fl-clean filterForm benefitApproveList__filterForm" :model="filterParams" layout="inline" style="padding-top: 10px;">
              <a-form-item label="公司">
                <app-enterprise-input v-model:value="filterParams.enterpriseName" style="width: 250px;" @change="changeEnterprise" />
              </a-form-item>
              <a-form-item label="企业卡">
                <a-select v-model:value="filterParams.cardNo" style="width: 180px;" placeholder="请选择企业卡" @change="changeEnterpriseCard">
                  <a-select-option v-for="item in cardList" :key="item.cardNo" :value="item.cardNo">{{ item.cardDesc }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-form>
          </div>
        </div>
      </div>
      <div v-if="cardData" class="businessCard-content">
        <div class="card-wrap">
          <div class="card" :class="cardData.cardType === 2? 'preferentialCard' : 'discountCard'">
            <div class="discounted">已优惠{{ formatCny(cardData.discountedAmount/100 || '') }}元</div>
            <div class="title">{{ cardData.cardType === 2 ? '优惠卡' : '折扣卡' }}</div>
            <div v-if="cardData.cardType === 2" class="price"><span class="big">{{ amount[0] }}.</span><span class="small">{{ amount[1] }}元</span></div>
            <div v-if="cardData.cardType === 1" class="price"><span class="big">{{ cardData.discountProportion/10 }}</span><span class="small">折</span></div>
            <div class="bottom">
              <div v-if="cardData.cardType===2" class="total">共{{ cardData.couponTimes }}次</div>
              <div v-else-if="cardData.cardType===1 && cardData.discountTimesFlag" class="total">共{{ cardData.discountTimes }}次</div>
              <span v-else />
              <div v-if="cardData.endTime < 4088937599000" class="time">{{ formatDate(cardData.startTime, 'YYYY.MM.DD') }}-{{ formatDate(cardData.endTime, 'YYYY.MM.DD') }}</div>
              <div v-else class="time">长期有效</div>
            </div>
          </div>
        </div>

        <div class="detail-wrap">
          <ul>
            <li><label>获卡详情：</label><span>{{ cardData.cardDesc }}</span></li>
            <li><label>使用规则：</label>
              <span v-if="cardData.cardType === 2">满{{ cardData.couponFull/100 }}元减{{ cardData.couponReduction/100 }}元</span>
              <span v-if="cardData.cardType === 1">企业支付订单金额可享{{ cardData.discountProportion/10 }}折优惠
                {{ cardData.discountSingleToplimitFlag === 1 ? `，单笔最高优惠${cardData.discountSingleToplimit/100}元` : '' }}</span>
            </li>
            <li><label>适用范围：</label><span>{{ energyType }}</span></li>
            <li><label>使用情况：</label>
              <span v-if="cardData.cardType === 2">共{{ cardData.couponTimes }}次（剩余<b class="blone">{{ cardData.couponTimes-cardData.usageCount }}</b>次）</span>
              <span v-if="cardData.cardType === 1 && cardData.discountTimesFlag">共{{ cardData.discountTimes }}次（剩余<b class="blone">{{ cardData.discountTimes-cardData.usageCount }}</b>次）</span>
              <span v-if="cardData.cardType === 1 && !cardData.discountTimesFlag">已用<b class="blone">{{ cardData.usageCount }}</b>次</span>
            </li>
            <li>
              <label>有效期：</label>
              <span v-if="cardData.endTime < 4088937599000">{{ formatDate(cardData.startTime) }} 至 {{ formatDate(cardData.endTime) }}</span>
              <span v-else>长期有效</span>
            </li>
          </ul>
        </div>
      </div>
    </a-card>
    <a-card v-if="cardData" title="使用明细" class="businessCard-wrap">
      <p class="alreadyDiscount">共计优惠： <b>{{ formatCny(cardData.discountedAmount/100 || '') }}元</b></p>
      <app-table ref="tableRef" size="default" row-key="id" :columns="tableColumns" :data="loadTableData" :show-pagination="true" :scroll="{ x: true }" />
    </a-card>
  </div>
</template>

<script>
import { message } from 'ant-design-vue';
import { computed, onMounted, ref, reactive, watch } from 'vue';
import { LeftOutlined } from '@ant-design/icons-vue';
import { formatCny, formatWithIntl } from '@/utils/illuminate';

import { useStore } from 'vuex';
import { getEnterpriseCardDetail, getEnterpriseCardList, getCardListApi } from '@/apis/businessCard';
import { formatDate } from '@/core/filters';
import { orderSourceMap } from '@/utils/constants';


const tableColumns = [
  { title: '员工', dataIndex: 'userName', ellipsis: true },
  { title: '手机号', dataIndex: 'phone' },
  { title: '订单金额', dataIndex: 'payAmount', customRender: ({ text }) => formatCny(text) },
  { title: '企业支付金额', dataIndex: 'payRealAmount', customRender: ({ text }) => formatCny(text) },
  { title: '优惠金额', dataIndex: 'payDiscountAmount', customRender: ({ text }) => formatCny(text) },
  { title: '油站名称', dataIndex: 'gasName', width: '200px' },
  { title: '支付方式', dataIndex: 'payType', customRender: ({ text }) => orderSourceMap[text] },
  { title: '状态', dataIndex: 'orderStautsName' },
  { title: '时间', dataIndex: 'payDate', customRender: ({ text }) => formatDate(text) },
];

export default {
  name: 'Index',
  components: {
    LeftOutlined,
  },
  setup() {
    const tableRef = ref(null);

    const store = useStore();
    const filterParams = reactive({
      enterpriseId: store.state.User.enterprise?.enterpriseId,
      enterpriseName: store.state.User.enterprise?.enterpriseName,
      cardNo: undefined,
    });
    
    // cardData.value.flag 1就表示当前公司企业卡，2表示当前和子公司都没有 3标识子公司有当前没有
    const cardData = ref(null);

    const energyType = computed(() => cardData.value && cardData.value.energyType && cardData.value.energyType.split(',').map(item => {
      switch (item) {
        case 'gasoline': return '汽油';
        case 'diesel_oil': return '柴油';
        case 'natural_gas': return '天然气';
        case 'electricity': return '电';
        default: break;
      }
    }).join('、'));

    // 是否显示当前入口
    const isShow = computed(() => (cardData.value && ((cardData.value.cardType === 2 && (cardData.value.couponTimes - cardData.value.usageCount)) || (cardData.value.cardType === 1 && cardData.value.discountTimesFlag && (cardData.value.discountTimes - cardData.value.usageCount)) || (cardData.value.cardType === 1 && !cardData.value.discountTimesFlag))));

    const amount = computed(() => (formatWithIntl(((cardData.value.couponReduction / 100) * cardData.value.couponTimes) || 0).split('.')));

    const onRefreshTable = () => {
      tableRef.value && tableRef.value.refresh(true);
    };

    const loadTableData = async parameter => {
      try {
        if (!filterParams.cardNo) return {};
        const res = await getEnterpriseCardList({
          ...parameter,
          cardNo: filterParams.cardNo,
          enterpriseId: filterParams.enterpriseId,
        });
        console.log(parameter);
        if (parameter) return res.data;
      } catch (e) {
        cardData.value = null;
        message.error(e.message);
      }
    };

    const cardList = ref([]);

    const getCardList = async () => {
      try {
        const res = await getCardListApi({
          enterpriseId: filterParams.enterpriseId,
        });
        cardList.value = res.data;
        filterParams.cardNo = res.data[0]?.cardNo;
        cardData.value = res.data[0] || null;
      } catch (e) {
        cardList.value = [];
        cardData.value = null;
        return message.error(e);
      }
    };

    onMounted(async () => {
      await getCardList();
    });

    const changeEnterprise = async e => {
      filterParams.enterpriseId = e;
      await getCardList();
      onRefreshTable();
    };

    const changeEnterpriseCard = async e => {
      filterParams.cardNo = e;
      cardData.value = cardList.value.find(item => item.cardNo === e);
      onRefreshTable();
    };

    return {
      store,
      filterParams,
      changeEnterprise,
      tableColumns,
      loadTableData,
      tableRef,
      cardData,
      energyType,
      amount,
      isShow,
      cardList,
      getCardList,
      changeEnterpriseCard,
    };
  },
};
</script>

<style scoped lang="scss">
.busniessCard-page {
  margin-top: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.businessCard-wrap {
  width: 100%;

  &:first-child {
    margin-bottom: 20px;
  }

  .businessCard-content {
    display: flex;
    padding: 15px 0;

    .card-wrap {
      flex: 5;

      .card {
        width: 321px;
        height: 167px;
        margin: 0 auto;
        background-size: contain;
        position: relative;
        padding: 18px 16px 12px 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &.preferentialCard {
          background: url("~@/assets/busniessCard/preCard_bg.svg") no-repeat center center;
        }

        &.discountCard {
          background: url("~@/assets/busniessCard/disCard_bg.svg") no-repeat center center;

          .bottom {
            .total {
              padding: 0 11px;
              line-height: 23px;
              height: 23px;
              background: #ab9171;
              border-radius: 12px;
              text-align: center;
              font-size: 14px;
              font-weight: 400;
              color: #fff;
            }
          }
        }

        &:before {
          content: "";
          position: absolute;
          right: -3px;
          top: 21px;
          width: 4px;
          height: 23px;
          background: url("~@/assets/busniessCard/shadow.png") no-repeat center center;
          background-size: contain;
        }

        .discounted {
          height: 20px;
          background: #fff;
          border-radius: 10px 0 0 10px;
          padding-left: 9px;
          font-size: 12px;
          font-weight: 500;
          color: #cb9402;
          line-height: 20px;
          position: absolute;
          right: 0;
          top: 24px;
        }

        .title {
          font-size: 21px;
          font-weight: 500;
          color: #fff;
          line-height: 29px;
        }

        .price {
          text-align: center;

          .big {
            font-size: 43px;
            font-weight: bold;
            color: #fff;
            line-height: 47px;
          }

          .small {
            font-size: 19px;
            font-weight: normal;
            color: #fff;
            line-height: 21px;
          }
        }

        .bottom {
          display: flex;
          justify-content: space-between;

          .total {
            padding: 0 11px;
            line-height: 23px;
            height: 23px;
            background: #3f60cd;
            border-radius: 12px;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: #fff;
          }

          .time {
            font-size: 12px;
            font-weight: 400;
            color: #fff;
            line-height: 17px;
          }
        }
      }
    }

    .detail-wrap {
      flex: 7;

      ul {
        padding: 6px 0;

        li {
          display: flex;
          margin-bottom: 14px;

          label {
            width: 80px;
            text-align: right;
            font-size: 14px;
            font-weight: 400;
            color: #666;
            line-height: 20px;
          }

          span {
            flex: 1;
            font-size: 14px;
            font-weight: 400;
            color: #333;
            line-height: 20px;
            margin-left: 3px;

            .blone {
              color: #1677ff;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .alreadyDiscount {
    font-size: 16px;
    font-weight: 400;
    color: #333;
    line-height: 22px;
    margin-bottom: 18px;

    b {
      font-size: 16px;
      font-weight: 400;
      color: #1677ff;
      line-height: 22px;
    }
  }
}

.form-content {
  display: flex;

  .form-wrap {
    flex: 5;

    .card-form {
      margin: 0 auto;
    }
  }

  .detail-wrap {
    flex: 7;
  }
}
</style>


import { defineComponent, reactive, ref, watchEffect, onMounted, watch, getCurrentInstance } from 'vue';
import { useState } from '@/store';
import { useRoute } from 'vue-router';
import { message } from 'ant-design-vue';
import { request } from '@/utils/request';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';
import vehicleManage from './VehicleManage.vue';
import VehicleGroup from './VehicleGroup.vue';

export default defineComponent({
  name: 'AutoListView',
  components: {
    vehicleManage,
    VehicleGroup,
  },
  setup(props, ctx) {
    const route = useRoute();
    const activeKey = ref(1);
    const state = useState();
    let enterprise: any = reactive({});
    const vehicleGroupList = ref<Array<{ id: number, groupName: string}>>([]);

    const getVehicleGroupList = async () => {
      try {
        const { data } = await request.post(createApiUrl('/newlinkSass/vehicleGroup/queryList'));
        vehicleGroupList.value = data || [];
      } catch (e: Error & any) {
        return message.error(isValidateError(e) ? firstError(e) : e.message);
      }
    };

    watchEffect(() => {
      enterprise = state.enterprise.value;
    });
    watchEffect(() => {
      activeKey.value = Number(route.params.energyType) || 1;
    });
    watch(() => activeKey.value, (val: number) => {
      if (val !== 3) getVehicleGroupList();
    });

    onMounted(() => {
      getVehicleGroupList();
    });
    return {
      activeKey,
      enterprise,
      vehicleGroupList,
    };
  },
});


import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    visible: { type: Boolean, default: false },
    trackInfo: { type: Object, default: () => ({}) },
  },
  emits: ['close', 'update:visible'],
  setup(props, ctx) {
    const handleCancel = () => {
      ctx.emit('close');
      ctx.emit('update:visible', false);
    };

    return {
      handleCancel,
    };
  },
});


import { getPublicTempToken } from '@/apis/utils';
// import { message } from 'ant-design-vue';
const COS = require('cos-js-sdk-v5');

const config = {
  Bucket: '',
  Region: '',
};

// function createCosScript() {
//   return new Promise(resolve => {
//     const script: any = document.createElement('script');
//     script.type = 'text/javascript';
//     script.src = 'https://web.newlink.com/ty/czb-ms/libs/cos-js-sdk-v5.min.js';
//     document.getElementsByTagName('head')[0].appendChild(script);

//     if (script.readyState) { // IE浏览器
//       script.onreadystatechange = () => {
//         if (script.readyState === 'loaded' || script.readyState === 'complete') {
//           script.onreadystatechange = null;
//           resolve({ code: 200 });
//         } else {
//           resolve({ code: 500 });
//         }
//       };
//     } else {
//       script.onload = () => {
//         resolve({ code: 200 });
//       };
//       script.onerror = () => {
//         resolve({ code: 500 });
//       };
//     }
//   });
// }
// eslint-disable-next-line no-async-promise-executor
export const upload = (file: any) => new Promise(async (resolve, reject) => {
  try {
    // if (!(<any>window).COS) {
    //   const val: any = await createCosScript();
    //   if (val.code === 500) return message.error('cos加载失败~');
    // }
    if (!file) return reject('无效文件');
    const { data }: any = await getPublicTempToken();
    config.Bucket = data.bucketName;
    config.Region = data.region;
    const cos = new COS({
      // Domain: 'public-e.newlink.com', // 自定义加速域名
      Protocol: 'https:', // 请求协议： 'https:' 或 'http:'
      Method: 'GET',
      getAuthorization: (options: any, callback: any) => {
        callback({
          TmpSecretId: data.tmpSecretId,
          TmpSecretKey: data.tmpSecretKey,
          XCosSecurityToken: data.sessionToken,
          StartTime: data.startTime,
          ExpiredTime: data.expiredTime,
          requestId: data.requestId,
        });
      },
    });
    cos.uploadFile({ // 此处用了最简单的上传函数，此外，腾讯云还支持分片上传函数等等。
      Bucket: config.Bucket, // 存储桶名称
      Region: config.Region, // 地区
      Key: `nl-sass-pc/${file.name}`, // 图片名称
      StorageClass: 'STANDARD',
      Body: file, // 上传文件对象
      onHashProgress: (progressData: any) => {
        console.log('校验中', JSON.stringify(progressData));
      },
      // 进度条
      onProgress: (progressData: any) => {
        console.log('上传中', JSON.stringify(progressData));
      },
    }, (err: any, data: any) => { // 此处的两个传参是腾讯云返回的，
      // 这里的data如果是个带有Location等的对象，则说明上传成功。
      if (err) {
        console.log('err', err);
        return reject(err);
      }
      // 'tykj-prod-public-read-1305297355.cos.ap-beijing.myqcloud.com/nl-sass-pc/%E7%AB%99%E4%BA%8C%E7%BB%B4%E7%A0%812.png' => 'https://public-e.newlink.com/nl-sass-pc/%E7%AB%99%E4%BA%8C%E7%BB%B4%E7%A0%812.png'
      const reg = /^(https?:\/\/)?([^/]+)/i;
      const path = data.Location.replace(reg, 'https://public-e.newlink.com');
      return path ? resolve(path) : reject('无效路径');
    });
  } catch (e) {
    console.log(e);
  }
});


import { defineComponent, reactive, ref, computed, watchEffect, createVNode } from 'vue';
import pullAt from 'lodash/pullAt';
import { message, Modal } from 'ant-design-vue';
import { ExclamationCircleFilled } from '@ant-design/icons-vue';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';

export default defineComponent({
  name: 'AutoRemoveStaffModal',
  emits: ['complete'],
  setup(props, ctx) {
    const visible = ref(false);
    const autoDetail = ref<any>({ licensePlate: '', vin: '' });
    const staffList = ref<{ vehicleUserRelId: number; userName: string }[]>([]);

    const closeModel = () => {
      visible.value = false;
    };

    const onClose = (index: number) => {
      pullAt(staffList.value, [index]);
    };

    watchEffect(() => {
      if (staffList.value.length < 1) {
        closeModel();
      }
    });


    const submiting = ref(false);
    const onSubmit = async () => {
      if (!staffList.value.length) {
        visible.value = false;
        return;
      }

      try {
        await request.delete(
          createApiUrl('/newlinkSass/vehicle/vehicle-del-rel-for-list'),
          { data: { ids: staffList.value.map(item => item.vehicleUserRelId) } },
        );
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      ctx.emit('complete');
      message.success('员工移除完成');
      visible.value = false;
    };

    const remove = (detail: any, list: any[]) => {
      autoDetail.value = detail;
      staffList.value = list;
      visible.value = false;

      Modal.confirm({
        content: '确认批量解绑员工？',
        icon: createVNode(ExclamationCircleFilled),
        class: 'noneFooterBorder',
        onOk() {
          onSubmit();
        },
      });
    };

    return {
      visible,
      staffList,
      autoDetail,
      remove,

      submiting,
      onSubmit,
      closeModel,
      onClose,
    };
  },
});


import { computed, createVNode, defineComponent, onMounted, reactive, ref, h } from 'vue';
import { message, Modal } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { noEmptyProp } from '@/utils/illuminate';
import { InfoCircleOutlined, QuestionCircleFilled } from '@ant-design/icons-vue';


import { useStore } from 'vuex';
import { createApiUrl } from '@/utils/utils';
import type { TableColumn, TableOptions } from '@/components/Table';
import { request } from '@/utils/request';
import { useState } from '@/store';

import AddStaffModal from '@/views/parts/AddStaff.vue';
import RemoveStaffModal from './RemoveStaff.vue';

export default defineComponent({
  name: 'AutoListView',
  components: {
    AddStaffModal,
    RemoveStaffModal,
  },
  setup(props, ctx) {
    const route = useRoute();
    const store = useStore();
    const autoDetail = ref<any>({ licensePlate: '', vin: '' });
    const title = computed(() => `车辆:${autoDetail.value.licensePlate || autoDetail.value.vin}`);
    const filterParams = reactive({
      nameOrPhone: '',
    });

    // = ---------------------------- = 列表选中 = ---------------------------- =
    const tableSelectedRowKeys = ref<any[]>([]);
    const tableSelectedRows = ref<any[]>([]);

    const onTableSelectChange = (selectedRowKeys: any[], selectedRows: any[]) => {
      tableSelectedRowKeys.value = selectedRowKeys;
      tableSelectedRows.value = selectedRows;
    };

    const tableOptions = computed<TableOptions>(() => ({
      alert: { show: true, clear: () => { tableSelectedRowKeys.value = []; } },
      rowSelection: {
        selectedRowKeys: tableSelectedRowKeys.value,
        onChange: onTableSelectChange,
        getCheckboxProps: (record: any) => ({
          props: { name: 'check-line' },
        }),
      },
    }));

    // = ---------------------------- = 列表配置 = ---------------------------- =
    const tableColumns: TableColumn[] = [
      { title: '姓名', dataIndex: 'userName' },
      { title: '手机号码', dataIndex: 'phone' },
      { title: '操作', dataIndex: 'action', width: '100px', slots: { customRender: 'action' } },
    ];

    const loadDetail = async () => {
      let res;
      try {
        res = await request.get(createApiUrl('/newlinkSass/vehicle/vehicle-find-detail'), { params: { ...route.params } });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      autoDetail.value = res.data;
    };

    // 加载数据方法 必须为 Promise 对象
    let userNum = 0;
    const loadTableData = (parameter: any) => new Promise<void>((resolve, reject) => {
      request.get(
        createApiUrl('/newlinkSass/vehicle/vehicle-find-vehicle-user-list'),
        { params: { ...parameter, ...noEmptyProp(filterParams), id: route.params.id } },
      )
        .then((res: any) => {
          userNum = res.data.total;
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const tableRef = ref<any>(null);
    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    const addItemRef = ref<any>(null);
    const onAddItem = () => {
      if (store.getters['User/isScheduleModel'] && userNum) {
        Modal.confirm({
          title: '该车辆已有绑定员工',
          cancelText: '',
          okText: '关闭',
          cancelButtonProps: { prefixCls: 'hide' },
          icon: <InfoCircleOutlined />,
          content: '',
        });
      } else {
        addItemRef.value.add(route.params.id, autoDetail.value.vehicleNo);
      }
    };

    const removeMultipleItemRef = ref<any>(null);
    const onRemoveMultipleItem = () => {
      if (!tableSelectedRowKeys.value.length) {
        return message.error('请选择要移除的员工');
      }

      removeMultipleItemRef.value.remove(autoDetail.value, [...tableSelectedRows.value]);
    };

    const onDelItem = async (record: any) => {
      //
      try {
        await request.delete(
          createApiUrl('/newlinkSass/vehicle/vehicle-del-rel'),
          { data: { id: record.vehicleUserRelId } },
        );
        message.success('解绑成功');
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      onRefreshTable();
    };

    const removeById = (record: any) => {
      Modal.confirm({
        title: h('div', {
          class: 'changeMargin',
        },
        '确认解绑该员工 ？'),
        icon: createVNode(QuestionCircleFilled),
        onOk() {
          onDelItem(record);
        },
        onCancel() {
          //
        },
      });
    };

    const onLoadStaffList = async (departmentId: string) => {
      const res = await request.get(
        createApiUrl('/newlinkSass/vehicle/enterprise/staff/not/bind/list'),
        {
          params: { vehicleId: route.params.id, departmentId },
          timeout: 30000,
        },
      );
      return res.data.vehicleStaffDtos;
    };

    const onSubmitStaffList = (userCodes: string[]) => request.put(
      createApiUrl('/newlinkSass/vehicle/vehicle-add-user-for-list'),
      { id: route.params.id, vehicleNo: autoDetail.value.vehicleNo, userCodes },
    );

    onMounted(() => {
      loadDetail();
    });

    return {
      route,
      title,
      filterParams,
      tableColumns,
      tableRef,
      loadTableData,
      onRefreshTable,

      addItemRef,
      onAddItem,

      removeMultipleItemRef,
      onRemoveMultipleItem,

      onDelItem,
      tableOptions,
      removeById,

      onLoadStaffList,
      onSubmitStaffList,
    };
  },
});

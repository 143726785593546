import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    width: "800px",
    title: "轨迹信息",
    footer: null,
    onCancel: _ctx.handleCancel
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_descriptions, {
        bordered: "",
        column: 2
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_descriptions_item, { label: "员工姓名" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trackInfo.userName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_descriptions_item, { label: "员工手机号" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trackInfo.phone), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_descriptions_item, {
            label: "公司",
            span: 2
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trackInfo.enterpriseName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_descriptions_item, { label: "部门" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trackInfo.departmentName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_descriptions_item, { label: "OBD序列号" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trackInfo.obdCode), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_descriptions_item, { label: "实际里程" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trackInfo.realDistance), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_descriptions_item, { label: "推荐路线里程" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trackInfo.recommendDistance), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_descriptions_item, { label: "车辆名称" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trackInfo.vehicleName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_descriptions_item, { label: "车牌号码" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trackInfo.licensePlate), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible", "onCancel"]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "autoStaffList__content" }
const _hoisted_2 = { class: "fl-left" }
const _hoisted_3 = { class: "fl-right staffList_dis" }
const _hoisted_4 = { class: "fl-row" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_add_staff_modal = _resolveComponent("add-staff-modal")!
  const _component_remove_staff_modal = _resolveComponent("remove-staff-modal")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "utils__pageContainer autoStaffList" }, {
    title: _withCtx(() => [
      _createVNode(_component_router_link, {
        class: "link-goback",
        to: {name: 'auto.list', params: {energyType: _ctx.route.params.energyType}}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_app_icon, { type: "arrow-left" })
        ]),
        _: 1
      }, 8, ["to"]),
      _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_form, {
          class: "fl-clean autoStaffList__filterForm",
          form: _ctx.filterParams,
          layout: 'inline'
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    onClick: _ctx.onAddItem
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("添加员工")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_a_button, {
                    type: "primary",
                    onClick: _ctx.onRemoveMultipleItem
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("批量解绑")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_a_form_item, { label: "员工" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.filterParams.nameOrPhone,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterParams.nameOrPhone) = $event)),
                    placeholder: "员工姓名 / 手机号码",
                    "allow-clear": ""
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    ghost: "",
                    onClick: _ctx.onRefreshTable
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("查询")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["form"]),
        _createVNode(_component_app_table, {
          ref: "tableRef",
          size: "default",
          "row-key": "vehicleUserRelId",
          "show-pagination": true,
          columns: _ctx.tableColumns,
          alert: _ctx.tableOptions.alert,
          "row-selection": _ctx.tableOptions.rowSelection,
          data: _ctx.loadTableData
        }, {
          action: _withCtx(({ record }) => [
            _createElementVNode("span", _hoisted_4, [
              _createElementVNode("a", {
                onClick: ($event: any) => (_ctx.removeById(record))
              }, "解绑员工", 8, _hoisted_5)
            ])
          ]),
          _: 1
        }, 8, ["columns", "alert", "row-selection", "data"])
      ]),
      _createVNode(_component_add_staff_modal, {
        ref: "addItemRef",
        mode: _ctx.isScheduleModel ? 'single' : 'multiple',
        "load-staff-list": _ctx.onLoadStaffList,
        "submit-user-list": _ctx.onSubmitStaffList,
        onComplete: _ctx.onRefreshTable
      }, null, 8, ["mode", "load-staff-list", "submit-user-list", "onComplete"]),
      _createVNode(_component_remove_staff_modal, {
        ref: "removeMultipleItemRef",
        onComplete: _ctx.onRefreshTable
      }, null, 8, ["onComplete"])
    ]),
    _: 1
  }))
}